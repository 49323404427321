import { createApp, ref } from 'vue'
import { router } from './plugins/vue-router'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'
import VueDisqus from 'vue-disqus'
import './assets/stylus/app.css'
import { i18n } from '@/i18n'

import App from './App.vue'
import { createPinia } from 'pinia'

const pinia = createPinia()
const app = createApp(App)

app.use(i18n)
app.use(VueCookies)
app.use(router)
app.use(VueDisqus, { shortname: 'granja-caimito' })
app.use(VueGtag, {
  config: {
    id: 'G-LZ07948Q0S',
    enabled: window.VUE_APP_PROFILE == 'production' ? true : false
  }
})
app.$cookies.config('30d','','',false, true)
app.use(pinia)

app.config.productionTip = false

const cookieConsent = ref(app.$cookies.get('cookie-consent') === 'true' ? true : false)
app.config.globalProperties.$cookieConsent = {
  value: cookieConsent,
  accept() { cookieConsent.value = true },
  decline() { cookieConsent.value = false },
  accepted() { return cookieConsent.value }
}

app.mount('#app')
