import { isEnabled, LIST_ANIMALS, SPONSOR_ANIMALS, PORK_MEAT_SALES, BEEF_SALES, INVESTMENT, COMMUNITY, MEMBERS } from '@/features'
import axios from 'axios'
import oauth from 'axios-oauth-client'
import tokenProvider from 'axios-token-interceptor'
import moment from 'moment'

export function setupFeatureFlags() {
  const hasPorkMeatSales = isEnabled(PORK_MEAT_SALES)
  const hasBeefSales = isEnabled(BEEF_SALES)
  const hasListAnimals = isEnabled(LIST_ANIMALS)
  const hasSponsorAnimals = isEnabled(SPONSOR_ANIMALS)
  const hasButcherShop = hasPorkMeatSales || hasBeefSales
  const hasInvestment = isEnabled(INVESTMENT)
  const hasCommunity = isEnabled(COMMUNITY)
  const hasMembers = isEnabled(MEMBERS)

    return {
    hasPorkMeatSales,
    hasBeefSales,
    hasListAnimals,
    hasSponsorAnimals,
    hasButcherShop,
    hasInvestment,
    hasCommunity,
    hasMembers
  }
}

export function getLanguage() {
  const language = navigator.language
  return language.substring(0, 2)
}

const getClientCredentials = oauth.client(axios.create(), {
  url: window.VUE_APP_TOKEN_URL,
  grant_type: 'client_credentials',
  client_id: 'caimito-eu',
  client_secret: window.VUE_APP_GRANJA_CLIENT_SECRET,
});

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  oauth.interceptor(tokenProvider, getClientCredentials)
)

export function formatDateTime(i18n, value) {
  if (value === undefined || value == null )
    return ''

  const dateTime = new Date(value)
  const today = new Date()

  const diffTime = Math.abs(today - dateTime);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 7)
    return Intl.DateTimeFormat(navigator.language, {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    }).format(dateTime)
  else if (diffDays > 1)
    return Intl.DateTimeFormat(navigator.language, {
      weekday: 'long',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    }).format(dateTime)
  else {
    let prefix = ''
    const todayDate = today.getDate()
    const valueDate = dateTime.getDate()

    if (todayDate == valueDate)
      prefix = i18n.t('terms.today')
    else
      prefix = i18n.t('terms.yesterday')

    return prefix + ', ' + Intl.DateTimeFormat(navigator.language, {
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    }).format(dateTime)
  }
}

export function formatDuration(value) {
  const duration = moment.duration(value);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  let output = '';
  if (days > 0) {
    output += `${days} day${days > 1 ? 's' : ''} `;
  }

  if (hours > 0) {
    output += `${hours}:`;
  }
  if (minutes > 0 || hours > 0) {
    output += `${minutes.toString().padStart(2, '0')}:`;
  }
  if (seconds > 0 || minutes > 0 || hours > 0) {
    output += `${seconds.toString().padStart(2, '0')}`;
  }
  return output;
}

export function formatMonthYear(date) {
  if (date === null || date === undefined)
    return 'No date given'

  const monthYear = moment(date).format('MMMM YYYY');
  return monthYear;
}

export function getMemberPublicId (app) {
  return app.$cookies.get('memberPublicId')
}
