export default [
  {
    path: '/',
    name: 'home',
    children: [
      {
        path: '/en',
        name: 'home_EN',
        component: () => import('../views/HomeEN.vue'),
      },
      {
        path: '/de',
        name: 'home_DE',
        component: () => import('../views/HomeDE.vue'),
      },
      {
        path: '/es',
        name: 'home_ES',
        component: () => import('../views/HomeES.vue'),
      }
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News.vue'),
  },
  {
    path: '/concept/overview',
    alias: '/concept',
    name: 'concept',
    children: [
      {
        path: '/concept/overview/en',
        name: 'concept_EN',
        meta: {
          title: 'Concept Overview'
        },
        component: () => import('../views/concept/IndexEN.vue'),
      },
      {
        path: '/concept/overview/de',
        name: 'concept_DE',
        meta: {
          title: 'Konzeptüberblick'
        },
        component: () => import('../views/concept/IndexDE.vue'),
      },
      {
        path: '/concept/overview/es',
        name: 'concept_ES',
        meta: {
          title: 'Concepto'
        },
        component: () => import('../views/concept/IndexES.vue'),
      }
    ]
  },
  {
    path: '/concept/animals',
    name: 'animals',
    children: [
      {
        path: '/concept/animals/en',
        name: 'animals_EN',
        meta: {
          title: 'Animal Stewardship'
        },
        component: () => import('../views/concept/AnimalsEN.vue'),
      },
      {
        path: '/concept/animals/de',
        name: 'animals_DE',
        meta: {
          title: 'Tierhaltung'
        },
        component: () => import('../views/concept/AnimalsDE.vue'),
      },
      {
        path: '/concept/animals/es',
        name: 'animals_ES',
        meta: {
          title: 'Ganaderia'
        },
        component: () => import('../views/concept/AnimalsES.vue'),
      },
    ]
  },
  {
    path: '/concept/desertification',
    name: 'desertification',
    children: [
      {
        path: '/concept/desertification/en',
        name: 'desertification_EN',
        meta: {
          title: 'Desertification'
        },
        component: () => import('../views/concept/DesertificationEN.vue'),
      },
      {
        path: '/concept/desertification/de',
        name: 'desertification_DE',
        meta: {
          title: 'Versteppung'
        },
        component: () => import('../views/concept/DesertificationDE.vue'),
      },
      {
        path: '/concept/desertification/es',
        name: 'desertification_ES',
        meta: {
          title: 'Desertification'
        },
        component: () => import('../views/concept/DesertificationES.vue'),
      }
    ]
  },
  {
    path: '/concept/life-death',
    name: 'lifeDeath',
    meta: {
      title: 'Life and Death'
    },
    component: () => import('../views/concept/LifeDeath.vue'),
  },
  {
    path: '/concept/regenerative-agriculture',
    name: 'regenerativeAgriculture',
    children: [
      {
        path: '/concept/regenerative-agriculture/en',
        name: 'regenerativeAgriculture_EN',
        meta: {
          title: 'Regenerative Agriculture'
        },
        component: () => import('../views/concept/RegenerativeAgricultureEN.vue'),
      },
      {
        path: '/concept/regenerative-agriculture/de',
        name: 'regenerativeAgriculture_DE',
        meta: {
          title: 'Regenerative Landwirtschaft'
        },
        component: () => import('../views/concept/RegenerativeAgricultureDE.vue'),
      },
      {
        path: '/concept/regenerative-agriculture/es',
        name: 'regenerativeAgriculture_ES',
        meta: {
          title: 'Agricultura regenerativa'
        },
        component: () => import('../views/concept/RegenerativeAgricultureES.vue'),
      }
    ]
  },
  {
    path: '/concept/business',
    name: 'business',
    children: [
      {
        path: '/concept/business/en',
        name: 'business_EN',
            meta: {
          title: 'Is This A Business?'
        },
        component: () => import('../views/concept/BusinessEN.vue'),
      },
      {
        path: '/concept/business/de',
        name: 'business_DE',
            meta: {
          title: 'Ist das ein Geschäft?'
        },
        component: () => import('../views/concept/BusinessDE.vue'),
      },
      {
        path: '/concept/business/es',
        name: 'business_ES',
            meta: {
          title: '¿Es esto un negocio?'
        },
        component: () => import('../views/concept/BusinessES.vue'),
      }
    ]
  },
  {
    path: '/concept/dehesa',
    name: 'dehesa',
    children: [
      {
        path: '/concept/dehesa/en',
        name: 'dehesa_EN',
          meta: {
          title: 'New Dehesa'
        },
        component: () => import('../views/concept/DehesaEN.vue'),
      },
      {
        path: '/concept/dehesa/de',
        name: 'dehesa_DE',
          meta: {
          title: 'Neuer Hutewald'
        },
        component: () => import('../views/concept/DehesaDE.vue'),
      },
      {
        path: '/concept/dehesa/es',
        name: 'dehesa_ES',
          meta: {
          title: 'Nueva Dehesa'
        },
        component: () => import('../views/concept/DehesaES.vue'),
      }
    ]
  },
  {
    path: '/concept/price-slider',
    name: 'priceSlider',
    children: [
      {
        path: '/concept/price-slider/en',
        name: 'priceSlider_EN',
            meta: {
          title: 'Our approach to pricing'
        },
        component: () => import('../views/concept/PriceSliderEN.vue'),
      },
      {
        path: '/concept/price-slider/de',
        name: 'priceSlider_DE',
            meta: {
          title: 'Unser Weg der Preisfindung'
        },
        component: () => import('../views/concept/PriceSliderDE.vue'),
      },
      {
        path: '/concept/price-slider/es',
        name: 'priceSlider_ES',
            meta: {
          title: 'Fijación de precios'
        },
        component: () => import('../views/concept/PriceSliderES.vue'),
      }
    ]
  },
  {
    path: '/animals',
    name: 'animalsOverview',
    children: [
      {
        path: '/animals/en',
        name: 'animalsOverview_EN',
        meta: {
          title: 'Animals'
        },
        component: () => import('../views/animals/IndexEN.vue'),
      },
      {
        path: '/animals/de',
        name: 'animalsOverview_DE',
        meta: {
          title: 'Tiere'
        },
        component: () => import('../views/animals/IndexDE.vue'),
      },
      {
        path: '/animals/es',
        name: 'animalsOverview_ES',
        meta: {
          title: 'Animales'
        },
        component: () => import('../views/animals/IndexES.vue'),
      }
    ]
  },
  {
    path: '/animals/pigs',
    name: 'animalsPigs',
    children: [
      {
        path: '/animals/pigs/en',
        name: 'animalsPigs_EN',
        meta: {
          title: 'Pigs'
        },
        component: () => import('../views/animals/PigsEN.vue'),
      },
      {
        path: '/animals/pigs/de',
        name: 'animalsPigs_DE',
        meta: {
          title: 'Schweine'
        },
        component: () => import('../views/animals/PigsDE.vue'),
      }
    ]
  },
  {
    path: '/animals/cattle',
    name: 'animalsCattle',
    children: [
      {
        path: '/animals/cattle/en',
        name: 'animalsCattle_EN',
        meta: {
          title: 'Cattle'
        },
        component: () => import('../views/animals/CattleEN.vue'),
      },
      {
        path: '/animals/cattle/de',
        name: 'animalsCattle_DE',
        meta: {
          title: 'Rinder'
        },
        component: () => import('../views/animals/CattleDE.vue'),
      }
    ]
  },
  {
    path: '/animals/sheep',
    name: 'animalsSheep',
    children: [
      {
        path: '/animals/sheep/en',
        name: 'animalsSheep_EN',
        meta: {
          title: 'Sheep'
        },
        component: () => import('../views/animals/SheepEN.vue'),
      },
      {
        path: '/animals/sheep/de',
        name: 'animalsSheep_DE',
        meta: {
          title: 'Schafe'
        },
        component: () => import('../views/animals/SheepDE.vue'),
      }
    ]
  },
  {
    path: '/animals/dogs',
    name: 'animalsDogs',
    children: [
      {
        path: '/animals/dogs/en',
        name: 'animalsDogs_EN',
        meta: {
          title: 'Dogs'
        },
        component: () => import('../views/animals/DogsEN.vue'),
      },
      {
        path: '/animals/dogs/de',
        name: 'animalsDogs_DE',
        meta: {
          title: 'Hunde'
        },
        component: () => import('../views/animals/DogsDE.vue'),
      }
    ]
  },
  {
    path: '/animals/horses',
    name: 'animalsHorses',
    children: [
      {
        path: '/animals/horses/en',
        name: 'animalsHorses_EN',
        meta: {
          title: 'Horses'
        },
        component: () => import('../views/animals/HorsesEN.vue'),
      },
      {
        path: '/animals/horses/de',
        name: 'animalsHorses_DE',
        meta: {
          title: 'Pferde'
        },
        component: () => import('../views/animals/HorsesDE.vue'),
      }
    ]
  },
  {
    path: '/plants',
    name: 'plants',
    children: [
      {
        path: '/plants/en',
        name: 'plants_EN',
        meta: {
          title: 'Plants'
        },
        component: () => import('../views/plants/IndexEN.vue'),
      },
      {
        path: '/plants/de',
        name: 'plants_DE',
        meta: {
          title: 'Pflanzen'
        },
        component: () => import('../views/plants/IndexDE.vue'),
      }
    ]
  },  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/butcher-shop',
    name: 'butcherShop',
    meta: {
      title: 'Butchershop Granja Caimito'
    },
    component: () => import('../views/butcher/Index.vue'),
  },
  {
    path: '/notifications/:customerId',
    name: 'notifications',
    meta: {
      title: 'Notification'
    },
    component: () => import('../views/Notifications.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    children: [
      {
        path: '/shop/en',
        name: 'shop_EN',
        meta: {
          title: 'Shop Granja Caimito'
        },
        component: () => import('../views/shop/IndexEN.vue'),
      },
      {
        path: '/shop/de',
        name: 'shop_DE',
        meta: {
          title: 'Hofladen Granja Caimito'
        },
        component: () => import('../views/shop/IndexDE.vue'),
      },
    ]
  },
  {
    path: '/shop/your-own-cattle',
    name: 'yourOwnCattle',
    meta: {
      title: 'Your Own Cattle'
    },
    component: () => import('../views/shop/YourOwnCattle.vue')
  },
  {
    path: '/butcher-shop/iberico-meat',
    name: 'ibericoMeat',
    meta: {
      title: 'Shop Iberico Meat'
    },
    component: () => import('../views/butcher/IbericoMeatEN.vue')
  },
  {
    path: '/shop/product/605c6e77-e51b-45e1-aa9e-b32549db0152',
    name: 'SlicedHam',
    children: [
      {
        path: '/shop/product/605c6e77-e51b-45e1-aa9e-b32549db0152/de',
        name: 'SlicedHam_DE',
        meta: {
          title: 'Hofladen: Handgeschnittener iberischer Schinken'
        },
        component: () => import('../views/shop/SlicedHamDE.vue'),
      },
      {
        path: '/shop/product/605c6e77-e51b-45e1-aa9e-b32549db0152/en',
        name: 'SlicedHam_EN',
        meta: {
          title: 'Shop: Hand cut Iberian Ham'
        },
        component: () => import('../views/shop/SlicedHamEN.vue'),
      }
    ]
  },
  {
    path: '/shop/product/db73614c-d74a-4bef-839b-3123442a7fd8',
    name: 'SlicedHamAndChili',
    children: [
      {
        path: '/shop/product/db73614c-d74a-4bef-839b-3123442a7fd8/de',
        name: 'SlicedHamAndChili_DE',
        meta: {
          title: 'Hofladen: Iberischer Schinken und Chili'
        },
        component: () => import('../views/shop/HamAndChiliDE.vue'),
      },
      {
        path: '/shop/product/db73614c-d74a-4bef-839b-3123442a7fd8/en',
        name: 'SlicedHamAndChili_EN',
        meta: {
          title: 'Shop: Iberian Ham and Chili'
        },
        component: () => import('../views/shop/HamAndChiliEN.vue'),
      }
    ]
  },
  {
    path: '/shop/product/fdb968df-abc9-4d14-99e5-5bcc6006a119',
    name: 'RedMissile100',
    children: [
      {
        path: '/shop/product/fdb968df-abc9-4d14-99e5-5bcc6006a119/de',
        name: 'RedMissile100_DE',
        meta: {
          title: 'Hofladen: Chili Red Missile 100g'
        },
        component: () => import('../views/shop/RedMissile100DE.vue'),
      },
      {
        path: '/shop/product/fdb968df-abc9-4d14-99e5-5bcc6006a119/en',
        name: 'RedMissile100_EN',
        meta: {
          title: 'Shop: Chili Red Missile 100g'
        },
        component: () => import('../views/shop/RedMissile100EN.vue'),
      }
    ]
  },
  {
    path: '/shop/product/09a13d5e-d7e6-4e18-a538-ad1dbc4fc559',
    name: 'RedMissile300',
    children: [
      {
        path: '/shop/product/09a13d5e-d7e6-4e18-a538-ad1dbc4fc559/de',
        name: 'RedMissile300_DE',
        meta: {
          title: 'Hofladen: Chili Red Missile 300g'
        },
        component: () => import('../views/shop/RedMissile300DE.vue'),
      },
      {
        path: '/shop/product/09a13d5e-d7e6-4e18-a538-ad1dbc4fc559/en',
        name: 'RedMissile300_EN',
        meta: {
          title: 'Shop: Chili Red Missile 300g'
        },
        component: () => import('../views/shop/RedMissile300EN.vue'),
      }
    ]
  },
  {
    path: '/shop/product/c55eb01b-0d1e-4f9d-9af6-7d16e6678496',
    name: 'LegOfHam',
    children: [
      {
        path: '/shop/product/c55eb01b-0d1e-4f9d-9af6-7d16e6678496/en',
        name: 'LegOfHam_EN',
        meta: {
          title: 'Shop: Leg of ham'
        },
        component: () => import('../views/shop/LegOfHamEN.vue'),
      },
      {
        path: '/shop/product/c55eb01b-0d1e-4f9d-9af6-7d16e6678496/es',
        name: 'LegOfHam_ES',
        meta: {
          title: 'Tienda: Pierna de jamón'
        },
        component: () => import('../views/shop/LegOfHamES.vue'),
      },
      {
        path: '/shop/product/c55eb01b-0d1e-4f9d-9af6-7d16e6678496/de',
        name: 'LegOfHam_DE',
        meta: {
          title: 'Shop: Ganze Keule'
        },
        component: () => import('../views/shop/LegOfHamDE.vue'),
      }
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/ShoppingCart.vue'),
  },
  {
    path: '/plants/zones',
    name: 'zones',
    component: () => import('../views/plants/Zones.vue'),
  },
  {
    path: '/plants/zones/:zoneId',
    name: 'zoneDetails',
    component: () => import('../views/plants/ZoneDetails.vue'),
  },
  {
    path: '/animals/cattle/illnesses',
    name: 'BovineIllnesses',
    component: () => import('../views/animals/BovineIllnesses.vue'),
  },
  {
    path: '/investment',
    name: 'investmentOverview',
    component: () => import('../views/investors/Investors.vue'),
  },
  {
    path: '/community',
    name: 'communityOverview',
    component: () => import('../views/community/Overview.vue'),
  },
  {
    path: '/community/register/:id',
    name: 'communityRegister',
    component: () => import('../views/community/Register.vue'),
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('../views/members/Overview.vue'),
  },
]
