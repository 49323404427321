<template>
  <header>
    <nav>
      <div v-if="runtimeProfile != 'production'" class="runtimeProfileBanner">
        No action taken here is real. Profile: {{ runtimeProfile }}
      </div>
      <ul>
        <li>
          <router-link :to="{ name: 'home' }"><img class="logo" src="../assets/Logo.png" alt="Caimito" />
          </router-link>
        </li>
        <li>
          <router-link data-cy="conceptLink" :to="{ name: 'concept' }">{{ $t("mainMenu.concept") }}</router-link>
        </li>
        <li v-if="hasInvestment">
          <router-link data-cy="investmentLink" :to="{ name: 'investmentOverview' }">{{ $t("mainMenu.investment")
            }}</router-link>
        </li>
        <li v-if="hasCommunity">
          <router-link data-cy="communityLink" :to="{ name: 'communityOverview' }">{{ $t("mainMenu.community")
            }}</router-link>
        </li>
        <li v-if="hasButcherShop">
          <router-link data-cy="butcherShopLink" :to="{ name: 'butcherShop' }">{{
        $t("mainMenu.butcherShop")
      }}</router-link>
        </li>
        <li v-if="hasMembers">
          <router-link data-cy="memberLink" :to="{ name: 'members' }">{{ $t("mainMenu.members") }}</router-link>
        </li>
        <li>
          <router-link data-cy="shopLink" :to="{ name: 'shop' }">{{ $t("mainMenu.shop") }}</router-link>
        </li>
        <li>
          <router-link data-cy="cartLink" :to="{ name: 'cart' }">
            <mdicon name="cart-outline" />
          </router-link>
        </li>
        <li>
          <LanguageChanger />
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { setupFeatureFlags, getLanguage } from '@/library'
import LanguageChanger from '../components/LanguageChanger.vue'

export default {
  name: "Header",
  computed: {
    runtimeProfile () {
      return window.VUE_APP_PROFILE;
    }
  },
  setup () {
    return setupFeatureFlags();
  },
  methods: {
    languageCode (code) {
      return code === getLanguage();
    },
  },
  components: { LanguageChanger }
}
</script>

<style scoped>
NAV {
  background-color: rgb(235, 236, 235);
  margin-bottom: 1em;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 60px;
}

@media (prefers-color-scheme: dark) {
  NAV {
    background-color: rgb(76, 75, 75);
  }
}

NAV UL {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

LI {
  float: left;
  font-size: 150%;
  display: inline;
  text-align: center;
  line-height: 50px;
  padding-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
}

li a {
  text-decoration: none;
}

IMG.logo {
  padding-right: 50px
}

.runtimeProfileBanner {
  background-color: red;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 9999;
  position: absolute;
}

@media (max-width:440px) {
  .runtimeProfileBanner {
    font-size: 8pt;
  }
}

@media (max-width:440px) {
  LI {
    font-size: 100%;
    padding-left: 3px;
    padding-right: 3px;
    line-height: 30px;
  }

  IMG.logo {
    padding-right: 20px;
  }
}
</style>
