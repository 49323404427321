import { createRouter, createWebHistory } from 'vue-router';
import routes from '../routes';


export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

function routeToLanguage(toRoute, next) {
  // console.log('routeToLanguage entry', toRoute)

  const supportedLanguages = ['en', 'de', 'es']
  const languageCode = navigator.language
  // console.log('supportLanguages, languageCode', supportedLanguages, languageCode)

  if (supportedLanguages.includes(languageCode) && !toRoute.path.endsWith('/' + languageCode)) {
    const newRouteName = toRoute.name + '_' + languageCode.toUpperCase()
    // console.log('supported', languageCode, newRouteName)
    if (router.hasRoute(newRouteName)) {
      // console.log('has route', newRouteName)
      const newRoute = { name: newRouteName }
      next(newRoute)
    }else{
      const defaultRouteName = toRoute.name + '_EN'
      // console.log('falling back to default', defaultRouteName)

      if (!toRoute.path.includes('/en') && router.hasRoute(defaultRouteName))
        next(toRoute.path + '/en')
    }
  } else {
    const defaultRouteName = toRoute.name + '_EN'
    // console.log('default', defaultRouteName)

    if (!toRoute.path.includes('/en') && router.hasRoute(defaultRouteName)) {
      if (toRoute.path === '/')
        next(toRoute.path + 'en')
      else
        next(toRoute.path + '/en')
    }

  }
}

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Granja Caimito';

  routeToLanguage(toRoute, next)
  next()
})

export default {
  router,
};
