<template>
  <div v-if="!$cookieConsent.accepted()">
    <div v-if="languageCode('es')" data-cy="cookieBanner" class="cookieLaw">
      <div>
        <p>
          Por razones técnicas, este sitio web puede utilizar cookies para funcionar correctamente. También utilizamos
          algunos servicios de terceros. para proporcionar contenido y funcionalidad que podría utilizar cookies
          propias. Algunos de estos servicios se encuentran fuera de la Unión Europea y podrían almacenar datos en el
          país desde el que operan.
        </p>
        <p>
          Vea nuestra <router-link :to="{ name: 'PrivacyPolicy' }">política de privacidad</router-link> para mas
          detalles.
        </p>
      </div>
      <div>
        <button class="decline" data-cy="decline" @click="decline">Rechazar</button>
        <button class="accept" data-cy="accept" @click="accept">Aceptar</button>
      </div>
    </div>
    <div v-else-if="languageCode('de')" class="cookieLaw">
      <div>
        <p>
          Aus technischen Gründen verwendet diese Website möglicherweise Cookies, um ordnungsgemäß zu funktionieren. Wir
          verwenden auch einige Dienstleistungen von Drittanbietern um Inhalte und Funktionen bereitzustellen, die
          möglicherweise eigene Cookies verwenden. Einige dieser Dienste befinden sich außerhalb der Europäischen Union
          und können Daten in dem Land speichern, in dem sie tätig sind.
        </p>
        <p>
          Weitere Details in unseren <router-link
            :to="{ name: 'PrivacyPolicy' }">Datenschutz-Bestimmungen</router-link>.
        </p>
      </div>
      <div>
        <button class="decline" data-cy="decline" @click="decline">Ablehnen</button>
        <button class="accept" data-cy="accept" @click="accept">Einverstanden</button>
      </div>
    </div>
    <div v-else class="cookieLaw">
      <div>
        <p>
          For technical reasons this website might use cookies to function properly. We also use some third-party
          services
          to provide content and functionality which might use cookies of their own. Some of these services are located
          outside the European Union and might store data in the country they operate from.
        </p>
        <p>
          See our <router-link :to="{ name: 'PrivacyPolicy' }">privacy policy</router-link> for further details.
        </p>
      </div>
      <div>
        <button class="decline" data-cy="decline" @click="decline">Decline</button>
        <button class="accept" data-cy="accept" @click="accept">Accept</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getLanguage } from '@/library'

export default {
  name: "CookieLaw",
  components: {},

  computed: {
    showBanner() {
      console.log('consent ', this.$cookieConsent)
      return this.$cookieConsent === false
    }
  },

  methods: {
    languageCode(code) {
      return code === getLanguage()
    },

    accept() {
      this.showBanner = false
      this.$cookies.set('cookie-consent', true, Infinity)
      this.$cookieConsent.accept()
    },

    decline() {
      this.showBanner = false
      this.$cookies.set('cookie-consent', false)
      this.$cookieConsent.decline()
    }
  }
};
</script>

<style scoped>
DIV.cookieLaw {
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  box-sizing: border-box;
  bottom: 0px;
  background-color: rgb(248 250 252);
  display: grid;
  grid-template-columns: 70% auto;
  align-items: center;
  gap: 2em;
  font-size: small;
}
</style>
