export const messages = {
  en: {
    terms: {
      today: 'Today',
      yesterday: 'Yesterday',
      open: 'Open',
      closed: 'Closed',
      alive: 'Alive',
      sick: 'Sick - Likely to be scheduled for slaugther'
    },
    mainMenu: {
      concept: 'Concept',
      projects: 'Projects',
      animals: 'Animals',
      plants: 'Plants',
      butcherShop: 'Butcher',
      shop: 'Shop',
      news: 'News',
      investment: 'Investment',
      community: 'Community',
      members: 'Members',
    },
    home: {
      claim: 'REGENERATIVE AGRICULTURE IN ANDALUCIA',
      subClaim: 'We build soil so that it nourishes plants. Plants make food for animals and people.'
    },
    priceBox: {
      header: 'Purchase details',
      unavailable: 'Unfortunately this product is currently not available.',
      quantity: 'Quantity',
      itemsAvailable: '{items} item(s) available',
      price: 'Price',
      pickYourPrice1: 'Please choose what this product is worth to you.',
      pickYourPrice2: 'Read about the reasoning behind the price slider.',
      support: 'Support for our restoration effort',
      moveToRight: 'You might want to consider supporting our project by moving the price slider above to the right.',
      donationThankYou: 'Thank your for your support of {amount} €',
      noDonation: 'We understand your situation. Maybe in the future you can support our project.',
      totalPrice: 'Your total price',
      totalPriceCalculated: '{ price } € incl. VAT',
      putIntoCart: 'Put into shopping cart',
      shoppingCartSuccess: 'The article was put into the shopping cart.',
      freeShipping: 'Free shipping with more than 20 € in the shopping cart.',
      seeCart: 'Check out'
    },
    shoppingCart: {
      title: 'Shopping Cart',
      product: 'Product',
      quantity: 'Quantity',
      customerPrice: 'Price',
      actions: 'Actions',
      clickPurchase: 'Click the Purchase button to see payment options.',
      buttonPurchase: 'Purchase',
      purchaseThankYou: 'Thank you for your purchase. We will send the product to:',
      checkEmail: 'Please also check your email at ',
      totalPrice: 'Total Cart Value',
      shippingCost: 'Shipping Cost',
      purchasePrice: 'Purchase Price',
      lineItemPrice: 'Total',
      noCartYet: 'Your shopping cart is empty.',
      freeShipping: 'Free Shipping',
      regularShipping: '10 € Regular (2-5 days)',
      fastShipping: '35 € Fast (2 days)'
    },
    animalsSidebar: {
      overview: 'Overview',
      pigs: 'Pigs',
      cattle: 'Cattle',
      sheep: 'Sheep',
      dogs: 'Dogs',
      horses: 'Horses'
    },
    plantsSidebar: {
      overview: 'Overview',
      zones: 'Zones'
    },
    conceptSidebar: {
      overview: 'Concept',
      desertification: 'Desertification',
      regenerativeAgriculture: 'Regenerative Agriculture',
      animalStewardship: 'Extensive Ranching',
      newDehesa: 'New Dehesa',
      isBusiness: 'Is this a business?',
      pricing: 'Pricing',
      animals: 'Animals',
      plants: 'Plants',
    },
    footer: {
      about: 'About',
      whatWeDo: 'What we do',
      contact: 'Contact us',
      legal: 'Legal',
      followUs: 'Follow Us'
    },
    youTubeLink: 'To see more visit our channel on YouTube',
    animalListing: {
      FEMALE: 'Cow',
      MALE: 'Bull',
      forSale: 'For Sale',
    },
    notifications: {
      title: 'Notifications',
      when: 'When',
      subject: 'Subject',
      message: 'Message',
      existingPreOrders: 'Your pre-orders',
      preOrderTable: {
        animal: 'Animal',
        quantity: 'Quantity',
        name: 'Name',
        price: 'Price'
      },
      noPreOrders: 'There are no pre-orders.'
    },
    butcherShop: {
      iberianPig: 'Iberian Pig',
      dehesaCattle: 'Dehesa Cattle'
    },
    plants: {
      zones: {
        title: 'Zones',
        latestImage: 'Latest Image',
        zone: 'Zone',
        size: 'Size',
        usage: 'Usage',
        lastAnimalAccess: 'Last Animal Access',
        usageType: {
          crop: 'Crop',
          pasture: 'Pasture',
          foodforest: 'Food Forest'
        }
      }
    },
    situationReport: {
      title: 'Situation Report for Zone {name}',
      zoneName: 'Zone Name',
      zoneSize: 'Zone Size',
      zoneUsage: 'Zone Usage',
      lastOpened: 'Last Opened',
      lastClosed: 'Last Closed',
      currentState: 'Current State'
    },
    project: {
      participantType: {
        VOLUNTEER: 'Volunteer',
        TEMPORARY_EMPLOYEE: 'Temporary Employee',
        CONTRACTOR: 'Contractor',
      }
    },
    members: {
      emailForm: {
        introduction: `To gain access to the members area please enter your email address.
          You will then receive a message with an access code.`,
        formLegend: 'Enter your email address',
        emailLabel: 'email address',
        submitButton: 'Request Access Code',
        registrationError: 'Cannot send email. Please contact us.',
        successMessage: `Your email with the access code has been sent. Please check your inbox for
         <mark>{email}</mark> and enter the code in the form.`
      },
      otpForm: {
        formLegend: 'Enter your access code',
        otpLabel: 'access code',
        submitButton: 'Login',
      }
    }
  },
  de: {
    terms: {
      today: 'Heute',
      yesterday: 'Gestern',
      open: 'Offen',
      closed: 'Geschlossen',
      alive: 'Lebend',
      sick: 'Krank - Soll wahrscheinlich geschlachtet werden'
    },
    mainMenu: {
      concept: 'Konzept',
      projects: 'Projekte',
      animals: 'Tiere',
      plants: 'Pflanzen',
      butcherShop: 'Metzgerei',
      shop: 'Hofladen',
      news: 'Notizblog',
      community: 'Community',
      investment: 'Investition',
      members: 'Mitglieder',
    },
    home: {
      claim: 'REGENERATIVE LANDWIRTSCHAFT IN ANDALUSIEN',
      subClaim: 'Wir bauen Boden auf, damit dieser Pflanzen ernährt. Pflanzen sind Nahrung für Tiere und Menschen.'
    },
    priceBox: {
      header: 'Bestellung',
      unavailable: 'Leider ist dieses Produkt derzeit nicht verfügbar.',
      quantity: 'Menge',
      itemsAvailable: '{items} Produkt(e) verfügbar',
      price: 'Preis',
      pickYourPrice1: 'Bitte wählen Sie aus, was dieses Produkt für Sie wert ist.',
      pickYourPrice2: 'Lesen Sie mehr über die Hintergründe des Preisschiebers.',
      support: 'Unterstützung für unsere Restaurierungsbemühungen',
      moveToRight: 'Vielleicht möchten Sie unser Projekt unterstützen, indem Sie den Preisschieberegler oben nach rechts bewegen.',
      donationThankYou: 'Vielen Dank für Ihre Unterstützung von {amount} €',
      noDonation: 'Wir verstehen Ihre Situation. Vielleicht können Sie in Zukunft unser Projekt unterstützen.',
      totalPrice: 'Ihr Gesamtpreis',
      totalPriceCalculated: '{ price } € incl. MwSt',
      putIntoCart: 'In den Warenkorb legen',
      shoppingCartSuccess: 'Der Artikel wurde in den Warenkorb gelegt.',
      freeShipping: 'Ab 20 € Warenwert kostenloser Versand.',
      seeCart: 'Einkauf abschließen'
    },
    shoppingCart: {
      title: 'Einkaufswagen',
      product: 'Produkt',
      quantity: 'Menge',
      customerPrice: 'Einzelpreis',
      actions: 'Aktionen',
      clickPurchase: 'Klicken Sie auf die Schaltfläche Kaufen, um die Zahlungsoptionen zu sehen.',
      buttonPurchase: 'Kaufen',
      purchaseThankYou: 'Vielen Dank für Ihren Kauf. Wir senden das Produkt an:',
      checkEmail: 'Wir haben eine email an Ihre Adresse geschickt: ',
      totalPrice: 'Wert im Warenkorb',
      shippingCost: 'Versandkosten',
      purchasePrice: 'Rechnungsbetrag',
      lineItemPrice: 'Preis',
      noCartYet: 'Ihr Einkaufswagen ist leer.',
      freeShipping: 'Kostenloser Versand',
      regularShipping: '10 € Regular (2-5 Tage)',
      fastShipping: '35 € Schnell (2 Tage)'
    },
    animalsSidebar: {
      overview: 'Überblick',
      pigs: 'Schweine',
      cattle: 'Rinder',
      sheep: 'Schafe',
      dogs: 'Hunde',
      horses: 'Pferde'
    },
    plantsSidebar: {
      overview: 'Überblick',
      zones: 'Zonen'
    },
    conceptSidebar: {
      overview: 'Konzept',
      desertification: 'Versteppung',
      regenerativeAgriculture: 'Regenerative Landwirtschaft',
      animalStewardship: 'Extensive Weidehaltung',
      newDehesa: 'Neue Dehesa',
      isBusiness: 'Ist dies ein Geschäft?',
      pricing: 'Preisfindung',
      animals: 'Tiere',
      plants: 'Pflanzen',
    },
    footer: {
      about: 'Über uns',
      whatWeDo: 'Was wir machen',
      contact: 'Kontakt',
      legal: 'Rechtliches',
      followUs: 'Folge us'
    },
    youTubeLink: 'Besuchen Sie unseren Kanal auf YouTube, um mehr zu erfahren',
    animalListing: {
      FEMALE: 'Kuh',
      MALE: 'Bulle',
      forSale: 'Zum Verkauf',
    },
    notifications: {
      title: 'Benachrichtungen',
      when: 'Wann',
      subject: 'Betreff',
      message: 'Nachricht',
      existingPreOrders: 'Ihre Vorbestellungen',
      preOrderTable: {
        animal: 'Tier',
        quantity: 'Menge',
        name: 'Name',
        price: 'Preis'
      },
      noPreOrders: 'Es gibt keine Vorbestellungen.'
    },
    butcherShop: {
      iberianPig: 'Iberico-Schwein',
      dehesaCattle: 'Dehesa-Rind'
    },
    plants: {
      zones: {
        title: 'Zonen',
        latestImage: 'Aktuelles Bild',
        zone: 'Zone',
        size: 'Größe',
        usage: 'Nutzung',
        lastAnimalAccess: 'Letzte Nutzung durch Tiere',
        usageType: {
          crop: 'Pflanzung',
          pasture: 'Weide',
          foodforest: 'Waldgarten'
        }
      }
    },
    situationReport: {
      title: 'Bericht über Zone {name}',
      zoneName: 'Name',
      zoneSize: 'Größe',
      zoneUsage: 'Nutzung',
      lastOpened: 'Zuletzt geöffnet',
      lastClosed: 'Zuletzt geschlossen',
      currentState: 'Aktueller Zustand'
    },
  },
  es: {
    terms: {
      today: 'Hoy',
      yesterday: 'Ayer',
      open: 'Abierto',
      closed: 'Cerrado',
      alive: 'Vivo',
      sick: 'Enfermo - Es probable que esté programado para el sacrificio'
    },
    mainMenu: {
      concept: 'Concepto',
      projects: 'Proyectos',
      animals: 'Animales',
      plants: 'Plantas',
      butcherShop: 'Carnicero',
      shop: 'Tienda',
      news: 'Noticias',
      community: 'Comunidad',
      investment: 'Inversión',
      members: 'Miembros',
    },
    home: {
      claim: 'AGRICULTURA REGENERATIVA EN ANDALUCIA',
      subClaim: 'Restauramos el suelo para que nutra a las plantas. Las plantas producen alimentos para los animales y las personas.'
    },
    animalsSidebar: {
      overview: 'Visión general',
      pigs: 'Cerdos',
      cattle: 'Ganado',
      sheep: 'Ovejas',
      dogs: 'Perros',
      horses: 'Caballos'
    },

    conceptSidebar: {
      overview: 'Concepto',
      desertification: 'Desertificación',
      regenerativeAgriculture: 'Agricultura regenerativa',
      animalStewardship: 'Ganaderia extensiva',
      newDehesa: 'Nueva Dehesa',
      isBusiness: '¿Es esto un negocio?',
      pricing: 'Fijación de precios',
      animals: 'Animales',
      plants: 'Plantas',
    },
    footer: {
      about: 'Sobre nosotros',
      whatWeDo: 'Lo que hacemos',
      contact: 'Contacta con nosotros',
      legal: 'Legal',
      followUs: 'Síganos'
    },
    youTubeLink: 'Para saber más visitenos en nuestro canal de YouTube',
    notifications: {
      title: 'Notificaciones',
      when: 'Cuando',
      subject: 'Sujeto',
      message: 'Mensaje',
      existingPreOrders: 'Tus pedidos anticipados',
      preOrderTable: {
        animal: 'Animal',
        quantity: 'Cantidad',
        name: 'Nombre',
        price: 'Precio'
      },
      noPreOrders: 'No hay pedidos anticipados.'
    },
    butcherShop: {
      iberianPig: 'Cerdo Ibérico',
      dehesaCattle: 'Vacuno de Dehesa'
    },
    plantsSidebar: {
      overview: 'Visión general',
      zones: 'Zonas'
    },
    plants: {
      zones: {
        title: 'Zonas',
        latestImage: 'Última imagen',
        zone: 'Zona',
        size: 'Tamaño',
        usage: 'Uso',
        lastAnimalAccess: 'Uso del último por animales',
        usageType: {
          crop: 'Cultivo',
          pasture: 'Pastizal',
          foodforest: 'Bosque comestible'
        }
      }
    },
    situationReport: {
      title: 'Reporte sobre zona {name}',
      zoneName: 'Nombre de zona',
      zoneSize: 'Tamaño de zona',
      zoneUsage: 'Uso de zona',
      lastOpened: 'Abierto por última vez',
      lastClosed: 'Cerrado por última vez',
      currentState: 'Estado actual'
    },
  }
}
