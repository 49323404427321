export const PORK_MEAT_SALES = Symbol('Pork meat sales')
export const BEEF_SALES = Symbol('Beef sales')
export const LIST_ANIMALS = Symbol('List animals')
export const SPONSOR_ANIMALS = Symbol('Sponsor animals')
export const INVESTMENT = Symbol('Investment')
export const COMMUNITY = Symbol('Community')
export const COMMUNITY_EVENT_REGISTRATION = Symbol('CommunityEventRegistration')
export const MEMBERS = Symbol('Members')

export const decisions = {
  [PORK_MEAT_SALES]: false, // window.VUE_APP_PROFILE === 'production' ? false : true,
  [BEEF_SALES]: false, // window.VUE_APP_PROFILE === 'production' ? false : true,
  [LIST_ANIMALS]: false,  // TODO: will become a different feature
  [SPONSOR_ANIMALS]: false, // window.VUE_APP_PROFILE === 'production' ? false : true,
  [INVESTMENT]: false, // window.VUE_APP_PROFILE === 'production' ? false : true,
  [COMMUNITY]: true,
  [COMMUNITY_EVENT_REGISTRATION]: window.VUE_APP_PROFILE === 'production' ? false : true,
  [MEMBERS]: window.VUE_APP_PROFILE === 'production' ? false : true,
}

export function isEnabled(decision) {
  return Boolean(decisions[decision])
}
