<template>
  <footer>
    <div>
      <img class="logo" src="../assets/Logo.png" alt="Caimito" />
      <p>
        14448 Conquista, Cordoba<br />
        Spain
      </p>
    </div>
    <div>
      <h1>{{ $t('footer.about') }}</h1>
      <ul>
        <li>
          <router-link data-cy="concept" :to="{ name: 'concept' }">{{ $t('footer.whatWeDo') }}</router-link>
        </li>
        <li>
          <a href="mailto:info@caimito.net?subject=From website">{{ $t('footer.contact') }}</a>
        </li>
      </ul>
    </div>
    <div>
      <h1>{{ $t('footer.legal') }}</h1>
      <ul>
        <li>
          <router-link data-cy="privacyPolicy" :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
        </li>
      </ul>
    </div>
    <div>
      <h1>{{ $t('footer.followUs') }}</h1>
      <ul class="social">
        <li>
          <a target="_blank" href="https://www.youtube.com/c/ProjectGranjaCaimito">
            <img src="../assets/icons/youtube-icon.svg" />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://twitter.com/GranjaCaimitoDE">
            <img src="../assets/icons/Twitter-logo.svg" />
          </a>
        </li>
        <li>
          <a v-if="languageCode('de')" target="_blank" rel="me" href="https://regenerate.social/@granjacaimitode">
            <img src="../assets/icons/mastodon.svg" />
          </a>
          <a v-else target="_blank" rel="me" href="https://regenerate.social/@granjacaimito">
            <img src="../assets/icons/mastodon.svg" />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.instagram.com/granjacaimitode">
            <img src="../assets/icons/instagram.png" />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.facebook.com/granjacaimitode">
            <img src="../assets/icons/facebook.svg" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { getLanguage } from '@/library'

export default {
  name: 'Footer',

  methods: {
    languageCode (code) {
      return code === getLanguage()
    },
  }
}
</script>

<style scoped>
FOOTER {
  border-top: solid black;
  background-color: rgb(237, 236, 236);
  display: flex;
  column-gap: 5px;
  justify-content: space-evenly;
  width: 100%;
}

@media (prefers-color-scheme: dark) {
  FOOTER {
    background-color: rgb(76, 75, 75);
  }
}


FOOTER H1 {
  font-size: 100%;
  white-space: nowrap;
}

FOOTER UL {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

FOOTER LI A {
  text-decoration: none;
}

IMG {
  max-width: 30px;
}

IMG.logo {
  max-width: unset;
}

UL.social {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

UL.social LI {
  display: inline;
  text-align: center;
  vertical-align: middle;
  padding: 3px;
}

UL.social LI A {
  text-decoration: none;
}

@media (max-width:440px) {

  FOOTER H1 {
    font-size: 90%;
    white-space: nowrap;
  }

  FOOTER LI {
    font-size: 90%;
    white-space: nowrap;
  }

}
</style>
