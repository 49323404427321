import { createI18n } from 'vue-i18n'
import { messages } from './messages'


const numberFormats = {
  'en': {
    decimal: {
      style: 'decimal',
      maximumFractionDigits: 1
    }
  },
  'es': {
    decimal: {
      style: 'decimal',
      maximumFractionDigits: 1
    }
  },
  'de': {
    decimal: {
      style: 'decimal',
      maximumFractionDigits: 1
    }
  },
}


export const i18n = createI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  messages,
  numberFormats,
  globalInjection: true
})
