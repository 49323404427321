<template>
  <div id="app">
    <Header />
    <router-view />
    <CookieLaw />
    <Footer />
  </div>
</template>
<script>
import Header from '@/layouts/Header.vue'
import CookieLaw from './components/CookieLaw.vue';
import Footer from './layouts/Footer.vue';

export default {
  name: "App",
  components: { Header, CookieLaw, Footer },
};
</script>
