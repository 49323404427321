<template>
  <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return { langs: ['es', 'en', 'de'] }
  },

  watch: {
    $i18n: {
      handler() {
        this.$router.push({ path: '/' + this.$i18n.locale, replace: true })
      },
      deep: true,
    },
  }
}
</script>
